import { lazy } from "react";
// default Tungsten Pages
export const RegisteredUsers = lazy(() => import('./RegisteredUsers'))
export const Users = lazy(() => import('./Users'))
export const SentEmails = lazy(() => import('./SentEmails'))
export const SentSMS = lazy(() => import('./SentSMS'))
export const SystemSettings = lazy(() => import('./SystemSettings'))
export const Companies = lazy(() => import('./Companies'))
export const Reports = lazy(() => import('./Reports'))
export const Dashboard = lazy(() => import('./Dashboard'))
export const Widgets = lazy(() => import('./Widgets'))
export const Invoices = lazy(() => import('./Invoices'))
export const Bills = lazy(() => import('./Bills'))
export const PermissionPreset = lazy(() => import('./PermissionPresets'))
export const EmailTemplates = lazy(() => import('./EmailTemplates'))
export const SMSTemplates = lazy(() => import('./SMSTemplates'))
export const DocumentTemplates = lazy(() => import('./DocumentTemplates'))
export const PaymentAccounts = lazy(() => import('./PaymentAccounts'))
export const PaymentRuns = lazy(() => import('./PaymentRuns'))
export const BackgroundProcesses = lazy(() => import('./BackgroundProcesses'))
export const DisconnectedProcesses = lazy(() => import('./DisconnectedProcesses'))

// customize Pages
export const Customers = lazy(() => import('./CustomPages/Customers'))
export const MyCompany = lazy(() => import('./CustomPages/MyCompany'))
// export const Invoices = lazy(() => import('./CustomPages/Invoices'))
export const ConfirmDebit = lazy(() => import('./CustomPages/ConfirmDebit'))
export const ConnectStripe = lazy(() => import('./CustomPages/ConnectStripe'))
export const FeeStructures = lazy(() => import('./CustomPages/FeeStructures'))
export const Clients = lazy(() => import('./CustomPages/Clients'))
export const PaymentRequests = lazy(() => import('./CustomPages/PaymentRequests'))
export const TerminalSetup = lazy(() => import('./CustomPages/TerminalSetup'))
export const OnBoardingStepProcess = lazy(() => import('./CustomPages/OnBoardingStepProcess'))