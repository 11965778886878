import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ServicesConfig from '@whitecobalt/tungsten/ServicesConfig';
import Application from '@app/index';
import RememberTableState from '@whitecobalt/tungsten/esm/components/Grid/RememberTableState';
import '@styles/main.scss';
import branding from '@config/branding';

ReactDOM.render(
	<React.StrictMode>
		<ServicesConfig>
			<BrowserRouter>
				<RememberTableState>
					<Application />
				</RememberTableState>
			</BrowserRouter>
		</ServicesConfig>
	</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if(branding.allowPrecache || branding.allowWebPush) {
// 	serviceWorkerRegistration.register({
// 		onUpdate: (registration) => {
// 			registration.waiting?.postMessage({type: 'SKIP_WAITING'})
// 			window.location.reload()
// 		}
// 	});
// } else {
// 	serviceWorkerRegistration.unregister()
// }
if(!branding.services.onesignal)
	serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();