import React from "react";
import { useLinearToken } from "@whitecobalt/tungsten/hooks/useLinearToken";
import { useReceiveUser } from "@whitecobalt/tungsten/hooks/useReceiveUser";
import { useUpdateApplication } from "@whitecobalt/tungsten/hooks/useUpdateApplication";
import { useCheckSessionID } from "@whitecobalt/tungsten/hooks/useCheckSessionID";
import { MaterialThemeProvider, MetronicLayoutProvider, MetronicSubheaderProvider } from "@metronic/layout";
import Routes from "./Routes";
import { useOneSignal } from "@hooks/useOneSignal";
import branding from "@config/branding";
import { useMenuPopups } from "@hooks/useMenuPopups";

export default function Application() {

	useLinearToken()
	useReceiveUser()
	useUpdateApplication(branding.services.env === 'production' ? 15 : 2)
	useOneSignal()
	useMenuPopups()
	useCheckSessionID()

	return (
		<MetronicLayoutProvider>
			<MetronicSubheaderProvider>
				<MaterialThemeProvider>
					<Routes />
				</MaterialThemeProvider>
			</MetronicSubheaderProvider>
		</MetronicLayoutProvider>
	);
}
