import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import Loader from "@whitecobalt/tungsten/Loader";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import branding from "@config/branding";
import {
    Users,
    RegisteredUsers,
    SentEmails,
    SentSMS,
	SystemSettings,
	Companies,
	Reports,
	Widgets,
	Dashboard,
	Customers,
	MyCompany,
	Invoices,
	DocumentTemplates,
	SMSTemplates,
	EmailTemplates,
	FeeStructures,
	ConfirmDebit,
	Clients,
	PaymentRequests,
	ConnectStripe,
	TerminalSetup,
	OnBoardingStepProcess,
	DisconnectedProcesses,
	BackgroundProcesses,
} from './pages'
import AuthRoutes from "./pages/Auth";
import { ErrorPage } from "./pages/ErrorPage";
import { ContentRoute, Layout } from "@metronic/layout";
import { useIsOrganisationAdmin } from "@hooks/useOrganisationAdmin";
import { useOrganisation } from "@hooks/useOrganisation";
import CustomPublic from "./pages/CustomPublic";

const MetronicRoute: any = ContentRoute

const Routes = () => {
	const [{ loading, user, others }, dispatch] = useSession();
	const organisation = useOrganisation()
	const isOnboardingProcess = !!organisation.data?.lastSetupStep && (organisation.data?.lastSetupStep > 1 && organisation.data?.lastSetupStep < 7)
	const hasAuthenthication = !!(user && (branding.allowOrganisationSelection ? others.hasSelectedOrganisation : true)) && isOnboardingProcess !== true
	const isOrganisationAdmin = useIsOrganisationAdmin()
	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname
			})
		}
	}


	if(loading) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4"  data-env-impose="true">
					<img src={branding.logo.splash} style={{maxWidth: 250}}/>
				</div>
				<Spinner animation="border" style={{borderColor: branding.color.primary, borderRightColor: 'transparent'}} />
			</Loader>
		)
	}

	return (
		<Switch>
			<Route path="/auth">
				<AuthRoutes />
			</Route>
			<Route path="/public">
				<CustomPublic />
			</Route>
			<ConditionalRoute
				condition={isOnboardingProcess}
				onRedirect={handleRedirect}
				redirectTo="/"
				path="/onboarding">
					<ErrorBoundary>
						<Suspense fallback={(<Loader active />)}>
							<Switch>
								<MetronicRoute path="/onboarding/process" component={OnBoardingStepProcess} />
								<MetronicRoute path="/onboarding/confirm-debit" component={ConfirmDebit} />
							</Switch>
						</Suspense>
					</ErrorBoundary>
			</ConditionalRoute>
			<ConditionalRoute
				condition={hasAuthenthication}
				onRedirect={handleRedirect}
				redirectTo={isOnboardingProcess ? '/onboarding/process' : '/auth/login'}
				path="/">
				<Layout>
					<ErrorBoundary>
						<Suspense fallback={(<Loader active />)}>
							<Switch>
								<MetronicRoute path="/admin/users" component={Users} />
								<MetronicRoute path="/registered-users" component={RegisteredUsers} />
								<MetronicRoute path="/emails" component={SentEmails} />
								<MetronicRoute path="/sms" component={SentSMS} />
								<MetronicRoute path="/syssettings" component={SystemSettings} />
								<MetronicRoute path="/reports" component={Reports} />
								<MetronicRoute path="/widgets" component={Widgets} />
								<MetronicRoute path="/dashboard" component={Dashboard} />
								<MetronicRoute path="/admin/disconnected-jobs" component={DisconnectedProcesses} />
								<MetronicRoute path="/admin/background-process" component={BackgroundProcesses} />
								{/** Custom Pages - Start */}
									<MetronicRoute path="/admin/customers" component={Customers} />
									<MetronicRoute path="/admin/invoices" component={Invoices} />
									<MetronicRoute path="/admin/email-templates" component={EmailTemplates} />
									<MetronicRoute path="/admin/sms-templates" component={SMSTemplates} />
									<MetronicRoute path="/admin/document-templates" component={DocumentTemplates} />
									<MetronicRoute path="/admin/fees" component={FeeStructures} />
									{isOrganisationAdmin && (
										<MetronicRoute path="/my-company" component={MyCompany} />
									)}
									<MetronicRoute path="/clients" component={Clients} />
									<MetronicRoute path="/confirm-debit" component={ConfirmDebit} />
									<MetronicRoute path="/stripe_callback" component={ConnectStripe} />
									<MetronicRoute path="/payments" exact={false} component={PaymentRequests} />
									<MetronicRoute path="/pay-on-terminal/:id/:location/:intentid?" exact={false} component={TerminalSetup} />
								{/** Custom Pages - End */}
								<Redirect path="/" exact to={branding.landingPath} />
								<Route path="*" component={ErrorPage}/>
							</Switch>
						</Suspense>
					</ErrorBoundary>
				</Layout>
			</ConditionalRoute>=
			{/* <Route path="*" component={ErrorPage}/> */}
		</Switch>
	);
}

export default Routes