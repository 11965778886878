import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import { SessionActionType, useSession } from "@whitecobalt/tungsten/esm/components/Session"
import { useMemo } from "react"

export const useOrganisation = () => {
    const [{user, others}, dispatch] = useSession()
    const request = useAuthAPI('/api/Me', { method: "GET" })

    return useMemo(() => {
        return {
            loading: request.loading,
            data: user?.organisations.find((each) => each.id === others.organisationID),
            refetch: () => {
                return request.call().then((response) => {
                    dispatch({
                        type: SessionActionType.SET_USER,
                        payload: response.data || null
                    })
                })
            }
        }
    }, [user, others.organisationID])
}