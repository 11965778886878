import { gql } from "@apollo/client";

export type DropdownCommonType<P = Record<string, any>> = {
  value: number;
  label: string;
} & P

export type DropdownQueryType<P = DropdownCommonType> = {
  dropdown: {
    items: P[];
  };
}

export const FontAwesomeIconsDropdownQuery = gql`
query FontAwesomeIconsDropdownQuery($where: FontAwesomeIconFilterInput) {
    dropdown: FontAwesomeIcons(where: $where) {
      items {
        value: CSSClass
        label: Description
      }
    }
  }
`;

export const ColourPalettesDropdownQuery = gql`
query ColourPalettesDropdownQuery($where: ColourPaletteFilterInput) {
    dropdown: ColourPalettes(where: $where) {
      items {
        value: HexCode
        label: Description
      }
    }
  }
`;

export const OrganisationsDropdownQuery = gql`
query OrganisationsDropdownQuery($where: OrganisationFilterInput) {
    dropdown: Organisations(where: $where) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const OrganisationsByTypeDropdownQuery = gql`
query OrganisationsByTypeDropdownQuery(
  $where: OrganisationFilterInput, 
  $organisationTypeID: Int!
  ) {
    dropdown: OrganisationsByType(
      where: $where, 
      OrganisationTypeID: $organisationTypeID
    ) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const SentryProjectDropdownQuery = gql`
query SentryProjectDropdownQuery($where: SentryProjectFilterInput) {
    dropdown: SentryProjects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const OrganisationTypesDropdownQuery = gql`
  query OrganisationTypesDropdownQuery{
    dropdown: OrganisationTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const ParameterTypesDropdownQuery = gql`
  query ParameterTypesDropdownQuery{
    dropdown: ParameterTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PermissionsDropdownQuery = gql`
  query PermissionsDropdownQuery($where: PermissionFilterInput) {
    dropdown: Permissions(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const JobRolesDropdownQuery = gql`
  query JobRolesDropdownQuery{
    dropdown: JobRoles(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UserTypesDropdownQuery = gql`
  query UserTypesDropdownQuery{
    dropdown: UserTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CountriesDropdownQuery = gql`
  query CountriesDropdownQuery{
    dropdown: Countries {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MobileCountriesDropdownQuery = gql`
  query MobileCountriesDropdownQuery($order: [CountrySortInput!]) {
    dropdown: Countries(order: $order) {
      items {
        value: ID
        label: PhonePrefix
      }
    }
  }
`;

export const TitlesDropdownQuery = gql`
  query TitlesDropdownQuery{
    dropdown: Titles {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EmailTemplatesDropdownQuery = gql`
  query EmailTemplatesDropdownQuery($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
      }
    }
  }
`;


export const ApplicationAreasDropdownQuery = gql`
  query ApplicationAreasDropdownQuery{
    dropdown: ApplicationAreas(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersDropdownQuery = gql`
  query UsersDropdownQuery($where: UserFilterInput) {
    dropdown: Users(where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const CompanyUsersDropdownQuery = gql`
  query CompanyUsersDropdownQuery($id: Int!, $where: UserFilterInput) {
    dropdown: UsersInOrganisation(iD: $id, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const UsersByUserTypesDropdownQuery = gql`
  query UsersByUserTypesDropdownQuery($userTypeIDs: [Int!], $where: UserFilterInput) {
    dropdown: UsersByUserTypes(userTypeIDs: $userTypeIDs, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const AccountSystemContactsDropdownQuery = gql`
  query AccountSystemContactsDropdownQuery($where: AccountSystemContactFilterInput) {  
    dropdown: AccountSystemContacts(where: $where) {
      items {
        value: ID
        label: Name
      }
    }
  }
`;

export const InvoicingModesDropdownQuery = gql`
  query InvoicingModesDropdownQuery($where: InvoicingModeFilterInput) {  
    dropdown: InvoicingModes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodeTypesDropdownQuery = gql`
  query BudgetAccountCodeTypesDropdownQuery($where: AccountCodeTypeFilterInput) {  
    dropdown: AccountCodeTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodesDropdownQuery = gql`
  query BudgetAccountCodesDropdownQuery($where: AccountCodeFilterInput) {  
    dropdown: AccountCodes(where: $where) {
      items {
        value: ID
        label: Description
        Code
      }
    }
  }
`;

export const BudgetVersionDropdownQuery = gql`
  query BudgetVersionDropdownQuery{  
    dropdown: BudgetVersions {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersInOrganisationImpersonationDropdownQuery = gql`
  query UsersInOrganisationImpersonationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      totalCount
      items {
        value: ID
      }
    }
  }
`;

export const UsersInOrganisationDropdownQuery = gql`
  query UsersInOrganisationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const ProjectsDropdownQuery = gql`
  query ProjectsDropdownQuery($where: ProjectFilterInput) {  
    dropdown: Projects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const TasksDropdownQuery = gql`
  query TasksDropdownQuery($where: TaskFilterInput) {  
    dropdown: Tasks(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;
/* CUSTOMS - START */

export const AvailableTagsDropdownQuery = gql`
  query {
    dropdown: AvailableTags {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const FeeStructuresDropdownQuery = gql`
  query($where: FeeStructureFilterInput) {  
    dropdown: FeeStructures(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PaymentRequestStatusesDropdownQuery = gql`
  query($where: PaymentRequestStatusFilterInput) {  
    dropdown: PaymentRequestStatuss(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ClientsDropdownQuery = gql`
  query($where: ClientFilterInput) {  
    dropdown: Clients(where: $where) {
      items {
        ID
        Firstname
        Surname
        EmailAddress
        MobileNumber
        PrivateClient
        MobileCountryID
      }
    }
  }
`;

export const MattersDropdownQuery = gql`
  query($where: MatterFilterInput) {  
    dropdown: Matters(where: $where) {
      items {
        value: ID
        label: MatterRef
      }
    }
  }
`;

export const LocationsDropdownQuery = gql`
  query($where: LocationFilterInput) {  
    dropdown: Locations(where: $where) {
      items {
        value: ID
        label: DisplayName
        StripeID
      }
    }
  }
`;

export const CompanyTypesDropdownQuery = gql`
  query($where: CompanyTypeFilterInput) {  
    dropdown: CompanyTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const GendersDropdownQuery = gql`
  query($where: GenderFilterInput) {  
    dropdown: Genders(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const RolesDropdownQuery = gql`
  query($where: RoleFilterInput) {  
    dropdown: Roles(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

// export const PaymentTerminalsDropdownQuery = gql`
//   query($where: PaymentTerminalFilterInput) {  
//     dropdown: PaymentTerminals(where: $where) {
//       items {
//         value: StripeID
//         label: Description
//         Location {
//           Value {
//             StripeID
//           }
//         }
//       }
//     }
//   }
// `;

export const ActivationStatusesDropdownQuery = gql`
  query($where: ActivationStatusFilterInput) {  
    dropdown: ActivationStatuses(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

/* CUSTOMS - END */